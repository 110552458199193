<template>
  <div>
    <b-table-simple small responsive>
      <b-thead>
        <b-tr>
          <b-th
            :colspan="allows_crud ? 6 : 5"
            style="text-align: center"
            class="pl-3 evaluation-header"
          >
            {{
              $getVisibleNames(
                "teaching.learningexperience",
                false,
                "Experiencia de Aprendizaje"
              ).toUpperCase()
            }}
            <div class="evaluation-button-div">
              <ButtonMedium
                v-if="allows_crud"
                :tooltip_text="`Agregar ${$getVisibleNames(
                  'teaching.learningexperience',
                  false,
                  'Experiencia de Aprendizaje'
                )}`"
                :click_button="
                  () =>
                    $bvModal.show(`add-learning-experience-modal-${matter_id}`)
                "
                :text_button="`Agregar`"
                :icon="'plus'"
                :variant="'primary'"
              >
              </ButtonMedium>
              <!-- <b-button
                v-if="allows_crud"
                class="mx-1"
                size="sm"
                @click="
                  $bvModal.show(`add-learning-experience-modal-${matter_id}`)
                "
                v-b-tooltip.left.noninteractive.v-secondary="
                  `Agregar  ${$getVisibleNames(
                    'teaching.learningexperience',
                    false,
                    'Experiencia de Aprendizaje'
                  )}`
                "
                >+ Agregar</b-button
              > -->
            </div>
          </b-th>
        </b-tr>
        <b-tr>
          <b-td
            style="width: 3%"
            class="text-center"
            v-if="allows_crud && draggableList.length > 0"
          >
          </b-td>
          <b-td style="width: 30%" class="text-center">
            <strong> NOMBRE </strong>
          </b-td>
          <b-td
            style="width: 30%"
            class="text-center"
            v-if="!show_competence_unit"
          >
            <strong> DESCRIPCIÓN </strong>
          </b-td>
          <b-td style="width: 35%" class="text-center">
            <strong>
              {{
                $getVisibleNames(
                  "teaching.ramicro",
                  true,
                  "RA Micro"
                ).toUpperCase()
              }}
            </strong>
          </b-td>
          <b-td
            style="width: 30%"
            class="text-center"
            v-if="show_competence_unit"
          >
            <strong>
              {{
                $getVisibleNames(
                  "teaching.tempcompetenceunit",
                  false,
                  "Unidad de competencia"
                ).toUpperCase()
              }}
            </strong>
          </b-td>
          <b-td
            class="text-center"
            v-if="institution && institution.internal_use_id == 'ciisa_uss'"
          >
            <strong> HORAS </strong>
          </b-td>
          <b-td
            style="width: 2%"
            v-if="allows_crud && draggableList.length > 0"
          ></b-td>
        </b-tr>
      </b-thead>
      <draggable
        v-model="draggableList"
        :disabled="!allows_crud"
        tag="b-tbody"
        handle=".handle"
      >
        <template v-if="draggableList.length > 0">
          <b-tr
            v-for="learning_experience in draggableList"
            :key="learning_experience.id"
          >
            <b-td
              class="text-center handle"
              :class="{ grabbable: allows_crud }"
              v-if="allows_crud"
              ><b-icon
                v-if="allows_crud"
                icon="arrows-expand"
                scale="1.2"
              ></b-icon
            ></b-td>
            <b-td class="text-center"> {{ learning_experience.name }}</b-td>
            <b-td v-if="!show_competence_unit">
              <div
                class="rich-text-content px-2"
                v-html="learning_experience.description"
              ></div>
            </b-td>
            <!-- study_units -->
            <b-td>
              <div class="d-flex align-center" style="justify-content: center">
                <div
                  v-for="study_unit in studyUnitsList.filter((x) =>
                    learning_experience.study_units.includes(x.id)
                  )"
                  :key="'study_units:' + study_unit.id"
                >
                  <div class="text-center">
                    <b-badge
                      class="ec-micro-badge"
                      v-b-tooltip.v-secondary.noninteractive="
                        `${study_unit.full_sentence}`
                      "
                    >
                      N°{{
                        study_unit.competence_unit_order != 999
                          ? study_unit.competence_unit_order + "."
                          : ""
                      }}{{ study_unit.order }}
                    </b-badge>
                  </div>
                </div>
              </div>
            </b-td>
            <b-td v-if="show_competence_unit">
              <div class="d-flex align-center" style="justify-content: center">
                <div
                  v-for="competence_unit in competenceUnits.filter((x) =>
                    learning_experience.temp_competence_units.includes(x.id)
                  )"
                  :key="'competence_units:' + competence_unit.id"
                >
                  <div class="text-center">
                    <b-badge
                      class="ec-micro-badge"
                      v-b-tooltip.v-secondary.noninteractive="
                        `${competence_unit.full_sentence}`
                      "
                    >
                      N°{{ competence_unit.order }}
                    </b-badge>
                  </div>
                </div>
              </div>
            </b-td>
            <b-td
              class="text-center"
              v-if="institution && institution.internal_use_id == 'ciisa_uss'"
            >
              {{ learning_experience.suggested_hours }}
            </b-td>
            <!-- actions -->
            <b-td class="text-center" v-if="allows_crud">
              <ButtonSmall
                v-if="allows_crud"
                :tooltip_text="`Editar ${$getVisibleNames(
                  'teaching.learningexperience',
                  false,
                  'Experiencia de Aprendizaje'
                )}`"
                :click_button="
                  () =>
                    $bvModal.show(
                      `edit-learning-experience-modal-${learning_experience.id}`
                    )
                "
                :class="``"
                :icon="'square'"
              ></ButtonSmall>
              <!-- <button-edit
                v-if="allows_crud"
                v-b-tooltip.v-secondary.noninteractive.top="
                  `Editar ${$getVisibleNames(
                    'teaching.learningexperience',
                    false,
                    'Experiencia de Aprendizaje'
                  )}`
                "
                fill="white"
                @click="
                  $bvModal.show(
                    `edit-learning-experience-modal-${learning_experience.id}`
                  )
                "
              ></button-edit> -->
              <ButtonSmall
                :click_button="
                  () => deleteLearningExperience(learning_experience.id)
                "
                :tooltip_text="`Eliminar ${$getVisibleNames(
                  'teaching.learningexperience',
                  false,
                  'Experiencia de Aprendizaje'
                )}`"
                :icon="'trash'"
              ></ButtonSmall>
              <!-- <button-delete
                v-b-tooltip.v-secondary.noninteractive.top="
                  `Eliminar ${$getVisibleNames(
                    'teaching.learningexperience',
                    false,
                    'Experiencia de Aprendizaje'
                  )}`
                "
                @click="deleteLearningExperience(learning_experience.id)"
              ></button-delete> -->
              <b-modal
                :id="`edit-learning-experience-modal-${learning_experience.id}`"
                :title="`Editar ${$getVisibleNames(
                  'teaching.learningexperience',
                  false,
                  'Experiencia de Aprendizaje'
                )}`"
                size="lg"
                hide-footer
                ignore-enforce-focus-selector="#sidebar-right"
              >
                <LearningExperienceForm
                  :matter_id="matter_id"
                  :max_order="maxOrder"
                  :LearningExperience="learning_experience"
                  :learning_experiences="filterLearningExperience"
                  :show_competence_unit="show_competence_unit"
                  :unique_ra="unique_ra"
                  @updated="updatedLearningExperience"
                >
                </LearningExperienceForm>
              </b-modal>
            </b-td>
          </b-tr>
        </template>
        <b-tr v-else>
          <b-td class="text-center" colspan="5"
            >No hay
            {{
              $getVisibleNames(
                "teaching.learningexperience",
                true,
                "Experiencias de Aprendizaje"
              )
            }}
            para mostrar.</b-td
          >
        </b-tr>
      </draggable>
    </b-table-simple>
    <b-modal
      :id="`add-learning-experience-modal-${matter_id}`"
      :title="`Crear ${$getVisibleNames(
        'teaching.learningexperience',
        false,
        'Experiencia de Aprendizaje'
      )}`"
      size="lg"
      hide-footer
      ignore-enforce-focus-selector="#sidebar-right"
    >
      <LearningExperienceForm
        :matter_id="matter_id"
        :max_order="maxOrder"
        :unique_ra="unique_ra"
        :show_competence_unit="show_competence_unit"
        :learning_experiences="filterLearningExperience"
        @created="createdLearningExperience"
      >
      </LearningExperienceForm>
    </b-modal>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import * as names from "@/store/names";
import { mapGetters } from "vuex";
// import { generateUniqueId } from "@/utils/utils";
export default {
  name: "LearningExperience",
  components: {
    draggable,
    LearningExperienceForm: () =>
      import("../Components/LearningExperienceForm.vue"),
    ButtonSmall: () =>
      import("@/components/reusable/Buttons/Small/ButtonSmall"),
    ButtonMedium: () =>
      import("@/components/reusable/Buttons/Medium/ButtonMedium"),
  },
  props: {
    matter_id: {
      type: Number,
      required: true,
    },
    allows_crud: {
      type: Boolean,
    },
    unique_ra: {
      type: Boolean,
      default: false,
    },
    show_competence_unit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      institution: "getInstitution",
      studyUnits: names.STUDY_UNITS,
      learningExperiences: names.LEARNING_EXPERIENCES,
      matterEvaluations: names.MATTER_EVALUATIONS,
      competence_units: names.COMPETENCE_UNITS,
    }),
    filterLearningExperience() {
      if (!this.matter_id) return [];
      return this.learningExperiences.filter((x) => x.matter == this.matter_id);
    },
    filterMatterEvaluations() {
      if (!this.matter_id) return [];
      return this.matterEvaluations
        .filter((x) => x.matter == this.matter_id)
        .sort((a, b) => a.order - b.order);
    },
    competenceUnits() {
      if (!this.matter_id) return [];
      return this.competence_units.filter((x) => x.matter == this.matter_id);
    },
    studyUnitsList() {
      return this.studyUnits
        .filter((x) => x.matter == this.matter_id)
        .map((x) => {
          const competence_unit = this.competenceUnits.find(
            (c) => c.id == x.temp_competence_unit
          );
          return {
            ...x,
            competence_unit_order: competence_unit
              ? competence_unit.order
              : 999,
          };
        })
        .sort(function (a, b) {
          if (a.competence_unit_order > b.competence_unit_order) return 1;
          if (a.competence_unit_order < b.competence_unit_order) return -1;
          if (a.order > b.order) return 1;
          if (a.order < b.order) return -1;
          return 0;
        });
    },
    maxOrder() {
      let order = 0;
      this.filterLearningExperience.forEach((x) => (order = x.order));
      order += 1;
      return order;
    },
    draggableList: {
      get() {
        let list = this.filterLearningExperience;
        list.sort(function (a, b) {
          if (a.order < b.order) return -1;
          if (a.order > b.order) return 1;
          return 0;
        });
        let order = 1;
        list.forEach((element) => {
          if (element.order != order) element.order = order;
          order += 1;
        });
        return list;
      },
      set(list) {
        let order = 1;
        list.forEach((element) => {
          if (element.order != order) {
            element.order = order;
            if (!isNaN(element.id)) {
              this.updatedLearningExperience(element);
            }
          }
          order += 1;
        });
      },
    },
  },
  methods: {
    createdLearningExperience(learning_experience, matter_evaluations_ids) {
      this.$store
        .dispatch(names.POST_LEARNING_EXPERIENCE, learning_experience)
        .then((response) => {
          this.changeMatterEvaluations(response, matter_evaluations_ids);
          this.$bvModal.hide(`add-learning-experience-modal-${this.matter_id}`);
        });
    },
    updatedLearningExperience(learning_experience, matter_evaluations_ids) {
      this.$store
        .dispatch(names.UPDATE_LEARNING_EXPERIENCE, learning_experience)
        .then((response) => {
          if (matter_evaluations_ids)
            this.changeMatterEvaluations(response, matter_evaluations_ids);
          this.$bvModal.hide(`edit-learning-experience-modal-${response.id}`);
        });
    },
    async changeMatterEvaluations(learning_experience, matter_evaluations_ids) {
      const filter_matter_evaluations = this.filterMatterEvaluations.filter(
        (x) =>
          x.type != 4 &&
          x.type != 5 &&
          (matter_evaluations_ids.includes(x.id) ||
            x.learning_experience == learning_experience.id)
      );
      //Dejar en null las learning_experience de las matter_evaluation que no están en los matter_evaluation_ids
      for await (let matter_evaluation of filter_matter_evaluations.filter(
        (x) =>
          !matter_evaluations_ids.includes(x.id) &&
          x.learning_experience == learning_experience.id
      )) {
        await this.$store.dispatch(names.PATCH_MATTER_EVALUATION, {
          matter_evaluation_id: matter_evaluation.id,
          item: { learning_experience: null },
        });
      }
      // Agregar las experiencias a los matter_evaluation
      for await (let matter_evaluation of filter_matter_evaluations.filter(
        (x) =>
          matter_evaluations_ids.includes(x.id) &&
          x.learning_experience != learning_experience.id
      )) {
        await this.$store.dispatch(names.PATCH_MATTER_EVALUATION, {
          matter_evaluation_id: matter_evaluation.id,
          item: { learning_experience: learning_experience.id },
        });
      }
    },
    deleteLearningExperience(id) {
      this.$swal({
        title:
          "¿Está seguro de que desea eliminar la Experiencia de Aprendizaje?",
        text: "¡Esta acción no se podrá revertir!",
        type: "warning",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch(names.DELETE_LEARNING_EXPERIENCE, id);
        }
      });
    },
    getUnitCompetenceOrder(temp_competence_unit_id) {
      const competence_unit = this.competenceUnits.find(
        (x) => x.id == temp_competence_unit_id
      );
      return competence_unit ? competence_unit.order + "." : "";
    },
  },
  mounted() {},
  created() {},
};
</script>

<style scoped>
table th {
  vertical-align: middle;
  background-color: var(--primary-color);
  color: white;
  font-size: var(--primary-font-size);
  padding: 2px 2px;
}
.evaluation-header {
  color: black !important;
  background: var(--primary-color) !important;
  border: 1px solid #000 !important;
}
.evaluation-button-div {
  float: right;
  /* padding-right: 1rem; */
}
table td {
  border: 1px solid black;
  text-align: left;
  vertical-align: middle;
  font-size: var(--secondary-font-size);
  padding: 2px 2px;
}
.grabbable {
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}
.grabbable:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}
.ec-badge-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}
.ec-micro-badge {
  font-size: var(--thirth-font-size);
  text-align: center;
  width: fit-content !important;
  margin-right: 0.3em;
}
@media print {
  .ec-micro-badge {
    border: none;
  }
}
</style>